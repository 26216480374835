<template>
  <div class="art">
    <div id="screen">
      <div id="cube" class="cube">
        <!-- cube images -->
       	<!-- cube images -->
			<img id="1"  src="../../assets/images/artbackground.jpg" class='face' data-transform="translateZ(-400px)" style="outline:none !important">
			<img id="2"  :src="artList.length >= 7?artList[6]:img" class='face' data-transform="rotateY(90deg) translateZ(-400px)">
			<img id="3"  :src="artList.length >= 8?artList[7]:img"  class='face' data-transform="rotateY(-90deg) translateZ(-400px)">
			<img id="4"  :src="artList.length >= 9?artList[8]:img" class='face' data-transform="rotateY(180deg) translateZ(-400px)">
			<img id="5"  :src="artList.length >= 10?artList[9]:img" class='face' data-transform="rotateX(90deg) translateZ(-300px)">
			<img id="6"  :src="artList.length >= 11?artList[10]:img"  class='face' data-transform="rotateX(-90deg) translateZ(-300px)">
			<!-- bonus image -->
			<img id="7"  :src="imgsrc"  class='face' data-transform="rotateY(180deg) translateZ(305px)" style="visibility:hidden">
			<!-- buttons -->
			<img @click="changeImg(0)" :src="artList.length >= 1?artList[0]:img" class='face button' alt="7" data-transform="translateY(-80px) translateZ(-340px) scale(0.5)">
			<img @click="changeImg(1)" :src="artList.length >= 2?artList[1]:img" class='face button' alt="7" data-transform="translateX(-200px) translateY(-80px) translateZ(-340px) scale(0.5)">
			<img @click="changeImg(2)" :src="artList.length >= 3?artList[2]:img"  class='face button' alt="7" data-transform="translateX(200px) translateY(-80px) translateZ(-340px) scale(0.5)">
			<img @click="changeImg(3)" :src="artList.length >= 4?artList[3]:img" class='face button' alt="7" data-transform="translateY(80px) translateZ(-340px) scale(0.5)">
			<img @click="changeImg(4)" :src="artList.length >= 5?artList[4]:img" class='face button' alt="7" data-transform="translateX(-200px) translateY(80px) translateZ(-340px) scale(0.5)">
			<img @click="changeImg(5)" :src="artList.length >= 6?artList[5]:img"  class='face button' alt="7" data-transform="translateX(200px) translateY(80px) translateZ(-340px) scale(0.5)">
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/images/artbackground.jpg"
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      artList: [],
	  imgsrc:"",
	  img,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "overflow:hidden;");
  },
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
  methods: {
    init() {
	  this.artList = localStorage.getItem('ImgSrc').split(',');
	  console.log(this.artList)
      let arr = ["https://api.pulijiaoyu.org.cn/js/art/artDetailmain.js"];
      arr.forEach((item) => {
        let script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", item);
        document.getElementsByTagName("head")[0].appendChild(script);
      });
    },
	changeImg(index)
	{
		this.imgsrc = this.artList[index];
	}
  },
};
</script>
<style scoped>
@import "../../assets/css/art/normalize.css";
html {
  overflow: hidden;
  -ms-touch-action: none;
  -ms-content-zooming: none;
}
body {
  position: absolute;
  margin: 0px;
  padding: 0px;
  background: #fff;
  width: 100%;
  height: 100%;
}
#screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  perspective: 500px;
  -webkit-perspective: 500px;
}

.cube {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin: 0;
  user-select: none;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.face {
  position: absolute;
  margin-left: -300px;
  margin-top: -200px;
  width: 600px;
  height: 400px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transition: outline 0.5s ease-in-out 0s;
  cursor: pointer;
}

.button {
  position: absolute;
  margin-left: -150px;
  margin-top: -100px;
  width: 300px;
  height: 200px;
}
.face:hover {
  outline: rgba(255, 255, 255, 1) solid 10px !important;
}
</style>
